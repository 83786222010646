import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, lastValueFrom } from 'rxjs';
import { Reviewer, Tenant } from '../../store/reviewer/reviewer.interface';
import { getReviewer } from '../../store/reviewer/reviewer.selector';
import { SessionUser } from '../../store/user/user.interface';
import { DocumentService } from './document-service';
import { HttpBaseService } from './http-base.service';
import { constants } from '../constants/constants';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';
import { setReviewer } from '../../store/reviewer/reviewer.action';
import { SkillAndCredentialService } from './skill-credential.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewerService {

  reviewerTenants!: any;
  reviewer!: Reviewer;
  profileStrenght!: any;
  constants:any = constants;
  
 
  constructor(private http:HttpBaseService,private documentService:DocumentService,
     private store: Store<SessionUser>, private storageService:StorageService, public skillsAndCredentialService: SkillAndCredentialService) { 
    this.store.select(getReviewer).subscribe((reviewer:Reviewer)=>{
      this.reviewer = reviewer;
    });
  
  }

  mapFirmLogoUrl(id:any){
    return `${environment.clientHost}/${this.documentService.getDocumentPathById(id)}`;
  }

  getSelectedTenant(){
    return this.storageService.getTenant();
  }

  getLatestTenant(){
    const tenants:Tenant[] = this.getReviewerTenants();
    let currentTenant = null;
    if(tenants && tenants.length){
      if(tenants.length === 1){
        currentTenant = tenants[0];
      }else{
        currentTenant = tenants.find(
          (tenant) => tenant.isDefault === true
        );
        
      }
    }
    return currentTenant;
  }

  getReviewerTenants(){
    return this.reviewer.tenants;
  }

  getReviewer(){
    return this.reviewer;
  }

  fetchReviewer(){
    return this.http.get('my');
  }

  fetchReviewerAndUpdate(){
    return this.http.get('my').subscribe((resp:Reviewer)=>{
        this.store.dispatch(setReviewer(resp));
    });
  }

  getNotificationHistory(body?:any) {
    let offset = body?.offset || 0;
    let limit = 20;
    let userId = this.reviewer._id;
    return this.http.get(`users/getNotificationHistory?offset=${offset}&limit=${limit}&sessionUserId=${userId}`);
  };

  updateNotificationHistory(query: any, type: string) {
    query = { ...query, firmId: this.getSelectedTenant().firm._id, email: this.getReviewer().email};
    return this.http.put(`users/updateNotificationHistory`, {_id: query.id, query: query, type: type});
  };


  viewOffer(offerId:any) {
    return lastValueFrom(this.http.put('offers/' + offerId + '/view'));
  }

  getPositions() {
    return this.http.get('positions');
  }

  quitPosition(positionId:string, reason:string) {
    return this.http.post('my/quitPosition/' + positionId, {reason: reason});
  }
  
  getOffers() {
    return this.http.get('offers');
  }

  updateMarkedAvailability() {
    let reviewerId = this.reviewer._id;
    return this.http.put("users/" + reviewerId + "/markedAvailable");
  }

  acceptOffer(offerId:string): Promise<any>{
    return lastValueFrom(this.http.put('offers/' + offerId + '/accept'));
  }

  getPastProjects(){
    return this.http.get('my/pastProjects');
  }

  rejectOfferWithReason(id: string, reason: any) {
    return lastValueFrom(this.http.put(`offers/${id}/reject`, reason));
  }

  updateNotificationPreference(body: any): Observable<any> {
    return this.http.put(`users/notificationPreference`, body);
  }

  sendVerificationPhoneSms(phoneNo: any): Observable<any> {
    return this.http.put(`users/sendVerificationPhoneSms`, phoneNo);
  }

  updatePhoneNo(body: any): Observable<any> {
    return this.http.put(`users/updatePhone`, body);
  }

  setProfileStrength(reviewer:Reviewer){
    let determinateValue = 0;
    let strength = reviewer.profile.strength;
    
    if(strength.contact === true){
      determinateValue += constants.profileStrengthValue.contact;
    }
    if(strength.education === true){
      determinateValue += constants.profileStrengthValue.education;
    }
    if(strength.notificationPreference === true){
      determinateValue += constants.profileStrengthValue.notificationPreference;
    }
    if(strength.experience === true){
      determinateValue += constants.profileStrengthValue.experience;
    }
    if(strength.bar === true){
      determinateValue += constants.profileStrengthValue.bar;
    }
    if(strength.document === true){
      determinateValue += constants.profileStrengthValue.document;
    }
    if(strength.skill === true){
      determinateValue += constants.profileStrengthValue.skill;
    }
    return determinateValue;
  }

  async setProfileStrengthForStaftr(reviewer:Reviewer){

      let skillsExist = this.skillsAndCredentialService.getSkills();
      let credentialExist = this.skillsAndCredentialService.getCredentials();

      let determinateValue = 0;
      let currentFirm = this.storageService.getTenant()._id;
      let {tenants} = await lastValueFrom(this.fetchReviewer());
      let currentTenant = tenants.find((tenant: any) => tenant._id === currentFirm);
      let strength = currentTenant.strength || 0;
        if(strength.contact === true){
          determinateValue += constants.staftrProfileStrengthPublicValue.contact;
        }
        if(strength.education === true){
          determinateValue += constants.staftrProfileStrengthPublicValue.education;
        }
        if(strength.notificationPreference === true){
          determinateValue += constants.staftrProfileStrengthPublicValue.notificationPreference;
        }
        if(!credentialExist || strength.credentials === true){
          determinateValue += constants.staftrProfileStrengthValue.credentials;
        }
        if(strength.document === true){
          determinateValue += constants.staftrProfileStrengthValue.document;
        }
        if(!skillsExist || strength.skill === true){
          determinateValue += constants.staftrProfileStrengthValue.skill;
        }
        
        
        return determinateValue;
    
  }

  updateReviewer(reviewerData: any) {
    return this.http.put("my", reviewerData);
  }

  getCurrentTenant(): string {
    const tenants = this.getReviewerTenants();
    const currentTenant = tenants.find(
      (tenant) => tenant.isDefault === true
    );
    return currentTenant?.firm?._id || '';
  }

  getCriteriaBasedUserSkills(userId:string){
    return this.http.get(`users/${userId}/skills/criteria`);
  }

  getFilteredCriteriaBasedUserSkills(userId:string){
    return this.http.get(`users/${userId}/skills/filteredCriteria`);
  }
  
  getFilteredCredentialBasedUserSkills(userId:string){
    return this.http.get(`users/${userId}/credentials/filteredCredential`);
  }

  saveCriteriaReviewer(criterias:any) {
    return this.http.post('dynamicCriteria/reviewerCriteria', criterias);
  };

  getReviewerCriteria(id:string){
      return this.http.get(`dynamicCriteria/reviewerCriteria/${id}`);
  };

  getCases(){
    return this.http.get('my/cases');
  }
  
  sendVerificationCodeAgainstEmail(email: string): Observable<any> {
    return this.http.post(`users/sendVerificationEmail`, { email });
  }

  updateEmail(email: string, verificationCode: string): Observable<any> {
    return this.http.post(`users/updateEmail`, { email, verificationCode });
  }
  requestForAccountDeletion () {
    return this.http.post('gdpr/suspend');
  };

  changePassword (oldPass: string, newPass: string, confirmPass: string, refreshObject:any) {
    let pass:any = {};
    pass.oldPassword = oldPass;
    pass.newPassword = newPass;
    pass.confirmPassword = confirmPass;
    pass.refreshObject = refreshObject;
    return this.http.put('auth/changePassword', pass);
  };

  getReasons(): Array<{name: string, label: string}>{
    return [
      {name: 'On a short term project', label: 'On a short term project'},
      {name: "On a long term project", label: "On a long term project"},
      {name: 'Available - terms not acceptable', label: 'Available - terms not acceptable'},
      {name: 'No longer doing project work', label: 'No longer doing project work'}
    ];
  }

  generateResume(payload:any) {
    return this.http.put('my/generateResume',payload);
  }

  getToDoList(roleId: string, firmId: string){
    let param = '';
    if (roleId != '') {
      param = `&roleId=${roleId}`;
    }
    return this.http.get(`users/todoList?firmId=${firmId}${param}`);
  }

  getReviewerProfile (reviewerId:string, firmId:string): Observable<any> {
    return this.http.get(`users/${reviewerId}/firms/${firmId}/details`);
  };

  getReviewerAllPastProjects (reviewerId:string) : Observable<any> {
    return this.http.get(`users/${reviewerId}/pastProjects`);
  };

  getReviewerDocuSignDocuments (reviewerId:string, firmId:string): Observable<any> {
    return this.http.get(`documents/docuSign/user/${reviewerId}/firm/${firmId}`);
  }

  viewEnvelopeDocument(envelopeId:string, documentId:string) : Observable<any> {
    return this.http.get(`documents/docuSign/envelope/${envelopeId}/document/${documentId}`, {responseType: 'text'});
  }

  addNote(reviewerId:string, message:string) : Observable<any> {
    return this.http.post(`users/${reviewerId}/note`, {message:message});
  }

  getFirmReviewerDocuments (queryObject:any): Observable<any> {
    return this.http.get(`documents/firmReviewer`,{params:queryObject});
  }

  getAllTalentPoolByReviewerId(reviewerId:string) : Observable<any> {
    return this.http.get(`talentPool/getpools/${reviewerId}`);
  }

  shareResumeOnAnotherTenant(tenantId: string, obj: any) : Observable<any> {
    return this.http.put(`users/shareTenantData/${tenantId}`, obj);
  }

  mergeAccount(tenantId: string, email: string) : Observable<any> {
    return this.http.put(`users/mergeAccount/${tenantId}`, {email: email});
  }
}
